<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
@font-face {
  font-family: "DMSans-Bold";
  src: url(/fonts/DM_Sans/DMSans-Bold.ttf);
}
@font-face {
  font-family: "DMSans-BoldItalic";
  src: url(/fonts/DM_Sans/DMSans-BoldItalic.ttf);
}
@font-face {
  font-family: "DMSans-Italic";
  src: url(/fonts/DM_Sans/DMSans-Italic.ttf);
}
@font-face {
  font-family: "DMSans-Medium";
  src: url(/fonts/DM_Sans/DMSans-Medium.ttf);
}
@font-face {
  font-family: "DMSans-MediumItalis";
  src: url(/fonts/DM_Sans/DMSans-MediumItalic.ttf);
}
@font-face {
  font-family: "DMSans-Regular";
  src: url(/fonts/DM_Sans/DMSans-Regular.ttf);
}
</style>
